import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby"
import HeaderImage from "../components/header-image"
import Page from "../components/page"
import Section from "../components/section"
import styled from "styled-components"
import { Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup';
import colours from "../utils/colours"
import widths from "../utils/dimensions"
import Button from "../components/button"
import RightArrow from '../images/right-arrow-thin.svg'
import MoreInfoTiles from "../components/more-info-tiles"
import SocialIcons from "../components/social-icons"

let SectionHalves = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0 0 0;
  a{
    color: inherit;
  }
`

let SectionHalf = styled.div`
  width: 100%;
  text-align: center;
  @media screen and (min-width: ${ widths.breakPointSmallMedium }){
    width: 50%;
  }
`

let StyledForm = styled(Form)`
  margin: auto;
  padding: 0 0 110px 0;
  text-align: left;
  label{
    display: block;
    color: black;
    margin-top: 15px;
    margin-bottom: 2px;
  }
  
  // Honeypot field
  .surname{
    border: 1px solid red;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1
  }
  input{
    height: 40px;
  }
  input,
  textarea{
    width: 100%;
    padding-left: 10px;
    background-color: ${ colours.highlightGrey };
    border: none;
  }
  textarea{
    height: 200px;
    padding: 10px;
    display: block;
  }
  }
  .error{
    color: red;
  }
  .select{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    background-color: ${ colours.highlightGrey };
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding-left: 10px;
  }
  @media screen and (min-width: ${ widths.breakPointSmallMedium }){
    padding: 0 40px 0 0;
  }
`
let BottomBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
  z-index: 1000;
`
let FormMessage = styled.div`
  width: 100%;
  background-color: forestgreen;
  margin-top: 30px;
  line-height: 40px;
  text-align: center;
  margin-bottom: -40px;
  display: inline-block;
`
let StyledSocialIcons = styled(SocialIcons)`
  display: flex;
  justify-content: center;
  div{
    &:hover{
      a{
        color: ${colours.blue};
      }
    }
  }
`

const ContactPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "contact") {
                  id
                  contactPage {
                      address
                      addressTitle
                      email
                      formTitle
                      headerImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      phone
                      text
                      title
                  }
                  slug
                  seo {
                      title
                      metaDesc
                  }
              }
          }
      }
  `)
  let seoData = data.wpgraphql.pageBy.seo
  let pageData = data.wpgraphql.pageBy.contactPage

  // Create state for success message
  const [formMessage, setFormMessage] = useState('');

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  return(
    <Page
        seoTitle={ seoData.title }
        seoDescription={ seoData.metaDesc }
    >
      <HeaderImage
        image={ pageData.headerImage }
      />
      <Section>
          <h1 dangerouslySetInnerHTML={{ __html: pageData.title }}/>
          <p dangerouslySetInnerHTML={{ __html: pageData.text }}/>
          <SectionHalves>
            <SectionHalf>
              <h2>{ pageData.formTitle }</h2>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  phone: '',
                  customer: '',
                  message: '',
                  surname: '',
                }}
                onSubmit={
                  (values, actions) => {
                    // Remove honeypot key from object
                    let valuesLessSurname = (({ surname,  ...o }) => o)(values)
                    fetch("/", {
                      method: "POST",
                      headers: { "Content-Type": "application/x-www-form-urlencoded" },
                      body: encode({ "form-name": "enquiry-form", ...valuesLessSurname })
                    })
                      .then(() => {
                        setFormMessage('Your message has been successfully sent')
                        // alert('Success');
                        actions.resetForm()
                      })
                      .catch(() => {
                        setFormMessage('There was an error sending your message')
                      })
                      .finally(() => actions.setSubmitting(false))
                  }
                }
                validationSchema={ Yup.object().shape({
                  name: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Please enter your name'),
                  surname: Yup.string()
                    .max(0, 'Value in honeypot field'),
                  email: Yup.string()
                    .email('Invalid email address')
                    .required('Please enter your email address'),
                  phone: Yup.string()
                    .max(15, 'Must be 15 characters or less')
                    .required('Please enter your phone number'),
                  customer: Yup.string()
                    .required('Please select your use'),
                  message: Yup.string()
                    .max(500, 'Must be 500 characters or less')
                    .required('Please tell us how we can help you'),
                })}
              >
                {() => (
                  <StyledForm name="enquiry-form" data-netlify={ true } netlify-honeypot="surname" >

                    <label htmlFor="name">Name*</label>
                    <Field name="name"/>
                    <BottomBorder/>
                    <div className="error">
                      <ErrorMessage name="name" />
                    </div>

                    <div className="surname">
                      <Field name="surname" tabIndex="-1" autoComplete="off"/>
                    </div>

                    <label htmlFor="email">Email*</label>
                    <Field name="email" />
                    <BottomBorder/>
                    <div className="error">
                      <ErrorMessage name="email" />
                    </div>

                    <label htmlFor="phone">Phone*</label>
                    <Field name="phone" />
                    <BottomBorder/>
                    <div className="error">
                      <ErrorMessage name="phone" />
                    </div>

                    <label htmlFor="customer">Are you a:*</label>
                    <Field
                      as="select"
                      name="customer"
                      className={ 'select' }
                    >
                      <option value="" label="Please select">Please select</option>
                      <option value="Home owner" label="Home owner">Home owner</option>
                      <option value="Electrician" label="Electrician">Electrician</option>
                      <option value="Other" label="Other">Other</option>
                    </Field>
                    <div className="error">
                      <ErrorMessage name="customer" />
                    </div>

                    <label htmlFor="message">Message*</label>
                    <Field name="message" component="textarea"/>
                    <BottomBorder/>
                    <div className="error">
                      <ErrorMessage name="message" />
                    </div>

                    <Button
                      type="submit"
                      aria-label="Submit form button"
                      width={ '150px' }
                    >
                      Submit<RightArrow/>
                    </Button>

                    { formMessage.length > 0 &&
                      <FormMessage>
                        { formMessage }
                      </FormMessage>
                    }

                  </StyledForm>
                )}
              </Formik>
            </SectionHalf>
            <SectionHalf>
              <h2 dangerouslySetInnerHTML={{ __html: pageData.addressTitle }}/>
              <h3>Connected Switchgear</h3>
              <p>{ pageData.address }</p>
              <p><a href={"tel:" + pageData.phone }>{ pageData.phone }</a></p>
              <p><a href={"mailto:" + pageData.email}>{ pageData.email }</a></p>
              <StyledSocialIcons/>
            </SectionHalf>
          </SectionHalves>
      </Section>

      <Section backGColour={ colours.highlightGrey }>
        <MoreInfoTiles
          location={ location }
        />
      </Section>
    </Page>
  )
}
export default ContactPage
